import dynamic from 'next/dynamic';
import { isUserLoggedIn } from 'utils/auth';
import { NextPageContext } from 'next';
import Head from 'next/head';
import React from 'react';
import { OrderStatuses } from '@/constants/enums';

const LoginPage = dynamic(() => import('./Login'));

interface Props {}

const Login = (props: Props) => (
  <>
    <Head>
      <title>Shop101 | Supplier Panel Login</title>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
      />
    </Head>
    <LoginPage {...props} />
  </>
);

export const getServerSideProps = (ctx: NextPageContext) => {
  if (isUserLoggedIn(ctx)) {
    return {
      redirect: {
        destination: `/orders/${OrderStatuses.newpending}`,
        permanent: false,
      },
    };
  } else {
    return {
      props: {},
    };
  }
};

// LoginPage.getLayout = (page: React.ReactElement) => <FullPageLayout>{page}</FullPageLayout>

export default Login;
